/* eslint-disable @typescript-eslint/no-floating-promises */

/* eslint-disable react/prop-types */

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { OrderDetails } from '@components/ecommerce';
import { Layout } from '@components/layout';
import React from 'react';

const Summary = ({ pageContext }) => {
  const { page } = pageContext;

  return (
    <Layout {...page}>
      <OrderDetails />
    </Layout>
  );
};
export default Summary;
